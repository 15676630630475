<template>
  <div class="main-spacer">
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: "MainSpacer",
};
</script>

<style lang="scss" scoped>
.main-spacer {
  padding-left: $padding-vertical;
  padding-right: $padding-vertical;

  img {
    width: 100%;
  }
}
</style>
