<template>
  <head>
    <RouterLink to="/main"> 
      MainView
    </RouterLink>
  </head>
  <body>
    <RouterView/>
  </body>
</template>

<script>
export default {
  name: "App",
  components: {
  }
};
</script>
